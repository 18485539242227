.p-60-0 {
  padding: 60px 0;
}

.footer-paragraph,
.footer-title {
  color: #ffffff;
  font-size: 1.1875rem;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.footer-paragraph {
  margin-bottom: 75px;
}
.socials-container {
  margin-top: 20px;
  margin-bottom: 65px;
}
.socials-container img {
  margin: 0 12px;
}
.footer-signature {
  color: #ffffff;
  font-size: 1.1875rem;
}
@media (max-width: 768px) {
  .footer-paragraph {
    font-size: 0.9375rem;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 75px;
  }
  .footer-signature {
    font-size: 0.9375rem;
  }
}
