.customImage-container {
  position: relative;
}
.customImage-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradient-background-primary {
  top: 3%;
  left: 10%;
  width: 512px;
  height: 512px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(38, 209, 247, 0.4) 0%,
    rgba(38, 209, 247, 0.22) 30%,
    rgba(38, 209, 247, 0.1) 60.94%,
    rgba(38, 209, 247, 0) 80%
  );
}

.gradient-background-second {
  bottom: 0;
  right: 0;
  width: 624px;
  height: 624px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(229, 0, 255, 0.08) 0%,
    rgba(229, 0, 255, 0.05) 30%,
    rgba(229, 0, 255, 0.01) 40%,
    rgba(229, 0, 255, 0) 60%
  );
}

@media (max-width: 1250px) {
  .gradient-background-primary {
    top: 3%;
    left: 10%;
    width: 410px;
    height: 410px;
  }

  .gradient-background-second {
    bottom: 0;
    right: 0;
    width: 500px;
    height: 500px;
  }
}

.gradient-background-secondary {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(38, 209, 247, 0.42) 0%,
    rgba(38, 209, 247, 0.24) 30%,
    rgba(38, 209, 247, 0.13) 50%,
    rgba(38, 209, 247, 0) 100% /* Adjust opacity for smoother transition */
  );
}

.gradient-background-third {
  bottom: 5%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(38, 209, 247, 0.42) 0%,
    rgba(38, 209, 247, 0.24) 30%,
    rgba(38, 209, 247, 0.13) 50%,
    rgba(38, 209, 247, 0) 100% /* Adjust opacity for smoother transition */
  );
}
.customImage {
  position: relative;
  z-index: 1; /* Ensure it is in front of the background */
}
.spinner-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 4px solid #00a4b6;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00a4b6 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .customImage-container {
    margin: auto;
  }
}
/* background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(55, 150, 223, 0.7) 0%,
      rgba(55, 150, 223, 0.27) 60.94%,
      rgba(55, 150, 223, 0) 100%
    );*/
/*
@media (max-width: 1200px) {
  .gradient-background-primary,
  .gradient-background-second,
  .gradient-background-secondary,
  .gradient-background-third {
    width: 80%;
    height: auto;
  }

  .spinner-container {
    width: 60px;
    height: 60px;
  }

  .lds-ring {
    width: 60px;
    height: 60px;
  }

  .lds-ring div {
    width: 24px;
    height: 24px;
    margin: 6px;
    border-width: 3px;
  }
}
*/

@media (max-width: 420px) {
  .customImage-container {
    width: 90%;
  }
  .spinner-container {
    width: 100%;
  }
}

@media (max-width: 1250px) and (min-width: 768px) {
  .gradient-background-second {
    bottom: -22%;
    right: -35%;
  }
}
