@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-7px);
  }
  100% {
    transform: translateY(0);
  }
}

.d-mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .d-mobile-only {
    display: block;
  }
  .emojis-container {
    position: relative;
    height: 360px;
    margin-top: -50px;
  }
  .emojis-container img {
    position: absolute;
  }
  .heart {
    top: 0;
    left: -8%;
    animation: floating 2s ease-in-out infinite;
  }
  .camera {
    top: 40%;
    left: 0;
    animation: floating 2.5s ease-in-out infinite;
  }
  .smiley {
    top: 30%;
    right: -90px;

    animation: floating 3s ease-in-out infinite;
  }
  .lovely {
    top: 20%;
    animation: floating 3.5s ease-in-out infinite;
  }
}
