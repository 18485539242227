/* reset */

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul[role="list"],
ol[role="list"],
ul,
ol {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]),
a {
  text-decoration-skip-ink: auto;
  text-decoration: none;
  color: inherit;
}

img,
picture,
svg,
video,
canvas {
  max-width: 100%;
  height: auto;
}

img,
picture,
svg,
video,
canvas {
  vertical-align: middle;
}

img,
picture,
svg,
video,
canvas {
  background-repeat: no-repeat;
  background-size: cover;
}

input,
button,
textarea,
select {
  font: inherit;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

button {
  border: none;
  background: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
  font-weight: normal;
}

/* typography and utilities */

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.font-light {
  font-weight: 300;
}

.font-regular {
  font-weight: 400; /* Regular */
}

.font-demi-bold {
  font-weight: 600; /* Demi Bold */
}

.font-bold {
  font-weight: 700; /* Bold */
}
.font-color-primary {
  color: #373737;
}
.font-color-secondary {
  color: #00a4b6;
}

.font-color-alt {
  color: #585858;
}

.font-size-stats {
  font-size: 34px;
}

.font-size-stats-secondary {
  font-size: 18px;
}
@media (max-width: 400px) {
  .font-size-stats {
    font-size: 8vw;
  }
  .font-size-stats-secondary {
    font-size: 4vw;
  }
}
/* general utilities*/

.width-100px {
  width: 100px;
}

.margin-center {
  margin: 0 auto;
}
@media (min-width: 769px) {
  .d-flex-lg {
    display: flex;
  }

  .w-85 {
    width: 85%;
  }
  .gap-1 {
    gap: 8%;
  }
  .div-section {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children vertically */
  }
}

.section-container {
  max-width: 90%;
  width: 1200px;
  margin: 0 auto;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}

.gap-mid {
  gap: 8%;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.margin-top-home {
  margin-top: clamp(100px, 10vw, 200px);
}

.margin-30 {
  margin: 30px 0;
}

.margin-60 {
  margin: 60px 0;
}

.margin-top-auto {
  margin: auto 0;
}
.margin-sides-auto {
  margin: 0 auto;
}

.margin-top-60 {
  margin-top: 60px;
}
.margin-top-40 {
  margin-top: 40px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-bottom-above-footer {
  margin-bottom: clamp(100px, 10vw + 1rem, 250px);
}

.text-align-center {
  text-align: center;
}
.line-height-reduced {
  line-height: 1.2;
}

@media (max-width: 768px) {
  .section-container {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
  .display-none-mb {
    display: none;
  }
  .margin-auto-mobile {
    margin: auto;
  }

  .margin-top-100-mobile {
    margin-top: 100px;
  }
  .margin-mobile-only-35 {
    margin-top: 35px;
  }
  .margin-mobile-only-50 {
    margin-bottom: 50px;
  }
  body {
    text-align: center;
  }

  .justify-content-center-mb {
    justify-content: center;
  }
}
.gradient-background-global {
  position: absolute;
  border-radius: 50%;
  z-index: 0;
}

/* changing element order */

@media (max-width: 768px) {
  .section-container-order {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
}

/* global */

/* button */

.button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(0.9rem, 1vw + 0.8rem, 1.4375rem);
  width: 243px;
  height: 50px;
  cursor: pointer;
  background-color: #00a4b6;
  color: #ffffff;
  border-radius: 150px;
  border: none;
  outline: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}

/* Subtle state */
.button-primary:active {
  background-color: #ffffff;
  color: #00a4b6;
  outline: 2px solid #00a4b6;
}

/* Hover state */
.button-primary:hover {
  background-color: #ffffff;
  color: #00a4b6;
  outline: 2px solid #00a4b6;
}

/* Focus state */
.button-primary:focus {
  outline: 2px solid #00a4b6; /* White outline for focus */
}
@media (max-width: 768px) {
  .button-primary {
    width: 85%;
    margin: 0 auto;
  }
}
/* fonts */

h1 {
  font-size: clamp(2.0625rem, 3.125vw + 1rem, 3.625rem);
}

h2 {
  font-size: clamp(1.35rem, 1.75vw + 0.75rem, 2.375rem);
  margin-bottom: 30px;
}
p,
button {
  font-size: clamp(0.9rem, 1vw + 0.8rem, 1.4375rem);
}

@media (max-width: 768px) {
  p {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 420px) {
  p {
    font-size: 0.975rem;
  }
}

/* header image */

@media (max-width: 1250px) and (min-width: 768px) {
  .margin-bottom-ipad {
    margin-bottom: 80px;
  }
  .font-size-stats {
    font-size: calc(1rem + 1.4vw);
  }
}
