header {
  width: 1200px;
  max-width: 90%;
  padding: 56px 0 0 0;
  margin: 0 auto;
}
/*
main {
  width: 1130px;
  margin: 0 auto;
}*/

footer {
  width: 100%;
  background-color: #303030;
}
